import { Modal } from 'antd'
import React, { useState, useRef, useEffect } from 'react'

const VideoPlayer = ({ url, show, onCancel }) => {
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef(null)
  useEffect(() => {
    if (videoRef?.current) {
      const video = videoRef.current
      video.addEventListener('canplaythrough', () => {
        video.play()
        setPlaying(true)
        console.log('Ready')
      })
    }
  }, [show, videoRef])
  if (!url) return <></>

  const togglePlaying = () => {
    const video = videoRef.current
    if (playing) {
      video.pause()
    } else {
      video.play()
    }
    setPlaying(!playing)
  }
  const handleLoadedMetadata = () => {
    const video = videoRef.current
    if (!playing) {
      video.play()
      setPlaying(true)
    }
  }

  return (
    <Modal
      //   title="Create appointment"
      open={show}
      id="video-player-modal"
      // bodyStyle={{ minHeight: '80vh' }}
      onCancel={onCancel}
      bodyStyle={{ padding: 0, borderRadius: '20px' }}
      // className="modalStyle"
      // width="1080px"
      width="80%"
      height="100vh"
      footer={null}
      centered
      // destroyOnClose
    >
      <div style={{ textAlign: 'center' }}>
        <video style={{ maxWidth: '100%', maxHeight: '100vh' }} ref={videoRef} controls onLoadedMetadata={handleLoadedMetadata}>
          <source src={url} type="video/mp4" />
        </video>
      </div>
    </Modal>
  )
}

export default VideoPlayer
