import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CenteredHeadline, CenteredTopText } from '../../styles'
import { TimeSinceFormatted } from '../../utils/GraphUtils'

export const GraphHeadline = ({ logEntries = [], isLoading, unit = 'ºC' }) => {
  if (isLoading || !logEntries?.thisYear?.length)
    return (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <p>
          <Skeleton count={3} />
        </p>
      </SkeletonTheme>
    )
  const lastMeasurement = logEntries?.thisYear?.at(-1)
  const lastEntryDateUTC = new Date(lastMeasurement?.createdAt)
  if (lastEntryDateUTC) {
    return (
      <>
        <CenteredTopText>{logEntries?.description}</CenteredTopText>
        <CenteredHeadline>
          <span>
            Siste måling:{' '}
            <b style={{ marginRight: '1rem' }}>
              {parseFloat(lastMeasurement?.value).toFixed(1)}
              {unit}
            </b>
            (
            <TimeSinceFormatted date={lastEntryDateUTC} withTimeStamp timeOnly />)
          </span>
        </CenteredHeadline>
      </>
    )
  }
  return <></>
}
