import React, { memo, useState } from 'react'
import { useQuery } from 'react-query'

function fetchImage() {
  return fetch('https://linux.midteide.com/api/snapshots/last').then((response) => response.blob())
}

export function ImageComponent({ onClick }) {
  const { data, status } = useQuery('image', fetchImage, {
    refetchInterval: 60000 // Set the refetch interval to 1 minute (60000 milliseconds)
  })

  if (status === 'loading') {
    return <div>Loading...</div>
  }

  if (status === 'error') {
    return <div>Error fetching image</div>
  }

  if (!data) {
    return null // Handle the case when there is no data yet
  }

  const imageURL = URL.createObjectURL(data)

  return (
    <img
      // src="https://linux.midteide.com/api/snapshots/last"
      src={imageURL}
      alt="Last snapshot from camera"
      // style={{ margin: '1rem', borderRadius: 16, objectFit: 'cover', maxWidth: '100%', border: '2px solid red' }}
      className="graph-styles"
      onClick={onClick}
    />
  )
}
