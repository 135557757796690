import React from 'react'
import ReactDOM from 'react-dom/client'
import './App.scss'
import App from './App'
import { AppWrapper, LoadingModal } from './styles'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <AppWrapper>
    <ErrorBoundary
      fallback={
        <LoadingModal>
          <p style={{ textAlign: 'center' }}>There was an error</p>
        </LoadingModal>
      }
    >
      <App />
    </ErrorBoundary>
  </AppWrapper>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
