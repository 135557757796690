import { Button } from 'antd'
import React from 'react'
import { CenteredContent } from '../../styles'

export const SHOW_ALL_DATA = { years: 10 }
export const SHOW_LAST_WEEK = { days: 7 }
export const SHOW_LAST_DAY = { days: 1 }
export const SHOW_LAST_MONTH = { months: 1 }
export const SHOW_LAST_YEAR = { years: 1 }

export const GraphButtons = ({ showDataFromTimePeriod, setShowDataFromTimePeriod, styles }) => {
  return (
    <CenteredContent style={styles}>
      <div>
        <Button
          type={showDataFromTimePeriod === SHOW_LAST_DAY ? 'primary' : 'default'}
          shape="round"
          // size="large"
          onClick={() => setShowDataFromTimePeriod(SHOW_LAST_DAY)}
        >
          Siste døgn
        </Button>
        <Button
          type={showDataFromTimePeriod === SHOW_LAST_WEEK ? 'primary' : 'default'}
          shape="round"
          // size="large"
          onClick={() => setShowDataFromTimePeriod(SHOW_LAST_WEEK)}
        >
          Siste uke
        </Button>
        <Button
          type={showDataFromTimePeriod === SHOW_LAST_MONTH ? 'primary' : 'default'}
          shape="round"
          // size="large"
          onClick={() => setShowDataFromTimePeriod(SHOW_LAST_MONTH)}
        >
          Siste måned
        </Button>
        <Button
          type={showDataFromTimePeriod === SHOW_LAST_YEAR ? 'primary' : 'default'}
          shape="round"
          // size="large"
          onClick={() => setShowDataFromTimePeriod(SHOW_LAST_YEAR)}
        >
          Siste år
        </Button>
        <Button
          type={showDataFromTimePeriod === SHOW_ALL_DATA ? 'primary' : 'default'}
          shape="round"
          // size="large"
          onClick={() => setShowDataFromTimePeriod(SHOW_ALL_DATA)}
        >
          Vis all data
        </Button>
      </div>
    </CenteredContent>
  )
}
