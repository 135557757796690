import React, { useState, useRef, useEffect } from 'react'
import { ChatGPTQuoteText } from '../../styles'
import { useDaily } from '../../utils/fetchHooks'

const ChatGPTQuote = ({ title }) => {
  const { data: daily } = useDaily({ staleTime: 180000, refetchInterval: 60000 })
  const [text, setText] = useState('')
  const [isNegative, setIsNegative] = useState(false)
  useEffect(() => {
    if (title === 'oslo:outside') setText(isNegative ? daily?.osloGPTNegative : daily?.osloGPT)
    if (title === 'cabin:outside') setText(isNegative ? daily?.furutoppenGPTNegative : daily?.furutoppenGPT)
  }, [isNegative, setText, daily, title])

  if (!title) return <></>
  return <ChatGPTQuoteText onClick={() => setIsNegative((prev) => !prev)}>{text}</ChatGPTQuoteText>
}

export default ChatGPTQuote
