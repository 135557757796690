import React from 'react'
import { differenceInMinutes, sub } from 'date-fns'
import format from 'date-fns/format'
import { BoldOutlined } from '../components/Graph/styles'

const formatDateFull = (date) => format(date, "eee d. MMM yyyy 'kl.' HH:mm")
const formatTimeOnly = (date) => format(date, "'kl.' HH:mm")

export const TimeSinceFormatted = ({ date, withTimeStamp = false, timeOnly = false, style = {} }) => {
  const differenceMinutes = differenceInMinutes(new Date(), date)
  let returnString = ''

  if (withTimeStamp) returnString += timeOnly ? `${formatTimeOnly(date)}, ` : `Målt ${formatDateFull(date)} - `

  if (differenceMinutes <= 60)
    return (
      <span style={style}>
        {returnString} <i>{`${differenceMinutes}min siden`}</i>
      </span>
    )

  const differenceHours = Math.floor(differenceMinutes / 60)
  if (differenceHours <= 24)
    return (
      <span style={style}>
        {returnString}
        <i>{`${differenceHours && differenceHours + 't, '}${differenceMinutes % 60}min siden`}</i>
      </span>
    )

  const differenceDays = Math.floor(differenceHours / 24)
  return (
    <React.Fragment style={style}>
      {returnString}
      <i>{`${differenceDays + 'd, '} ${(differenceHours % 24) + 't, '}${differenceMinutes % 60}min siden`}</i>
    </React.Fragment>
  )
}

export const TemperatureFormatted = ({ temperature, style, unit = 'ºC' }) => (
  <BoldOutlined
    style={{
      color: 'white',
      // marginRight: '1rem',
      textShadow: temperature < 0 ? '1px 1px 2px blue, 0 0 1em blue, 0 0 0.2em blue' : '1px 1px 2px red, 0 0 1em red, 0 0 0.2em red',
      ...style
    }}
  >
    {`${typeof temperature === 'number' ? temperature.toFixed(1) : temperature}${unit}`}{' '}
  </BoldOutlined>
)

export const stringToDateUTC = (dateString) => parseInt(format(new Date(dateString), 'TT'))
