import { useState } from 'react'
import { CenteredContent } from '../styles'
import { DrawGraph } from '../components/Graph/DrawGraph'
import Navbar from '../components/Navbar/Navbar'
import { GraphHeadline } from '../components/GraphHeadLine/GraphHeadline'
import { GraphStats } from '../components/GraphStats/GraphStats'
import { useLogEntryDataset } from '../utils/fetchHooks'
import ChatGPTQuote from '../components/ChatGPTQuote/ChatGPTQuote'
import { SHOW_LAST_WEEK } from '../components/GraphButtons/GraphButtons'

const Furutoppen = ({}) => {
  const [showDataFromTimePeriod, setShowDataFromTimePeriod] = useState(SHOW_LAST_WEEK)
  const { data: logEntriesData, isLoading } = useLogEntryDataset(showDataFromTimePeriod, 'cabin:outside', 'temperature', {
    staleTime: 180000,
    refetchInterval: 60000
  })
  return (
    <div>
      <Navbar />
      <CenteredContent>
        <GraphHeadline graphTitle={logEntriesData?.description} logEntries={logEntriesData} isLoading={isLoading} />
        <ChatGPTQuote title="cabin:outside" />
        <GraphStats logentryDataset={logEntriesData} />
        <DrawGraph
          logentryDataset={logEntriesData}
          isLoading={isLoading}
          showDataFromTimePeriod={showDataFromTimePeriod}
          setShowDataFromTimePeriod={setShowDataFromTimePeriod}
        />
      </CenteredContent>
    </div>
  )
}

export default Furutoppen
