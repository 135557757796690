import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CenteredText } from '../../styles'
import { TemperatureFormatted, TimeSinceFormatted } from '../../utils/GraphUtils'
import './styles.scss'

export const GraphStats = ({ logentryDataset = [], isLoading, unit = 'ºC' }) => {
  if (!logentryDataset || !logentryDataset.avgThisYear) return <></>
  const { avgThisYear, avgLastYear, thisYear, lastYear, lowestThisYear, highestThisYear, lowestLastYear, highestLastYear } = logentryDataset
  if (isLoading)
    return (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <p>
          <Skeleton count={3} />
        </p>
      </SkeletonTheme>
    )
  if (logentryDataset.thisYear?.length) {
    return (
      <CenteredText>
        <div className="stats-container">
          <div className="highval">
            Høyeste valgt periode: <TemperatureFormatted temperature={highestThisYear.value} unit={unit} />
          </div>
          <div className="highdate">
            <TimeSinceFormatted date={new Date(highestThisYear.date)} withTimeStamp />
          </div>
          <div className="lowval">
            Laveste valgt periode: <TemperatureFormatted temperature={lowestThisYear.value} unit={unit} />
          </div>
          <div className="lowdate">
            <TimeSinceFormatted date={new Date(lowestThisYear.date)} withTimeStamp />
          </div>
          <div className="avgThisyear">
            Gjennomsnitt valgt periode: <TemperatureFormatted temperature={avgThisYear} unit={unit} />
          </div>
          {logentryDataset?.avgLastYear && (
            <div className="avgLastyear">
              Tilsvarende forrige år: <TemperatureFormatted temperature={avgLastYear} unit={unit} />
            </div>
          )}
          <div className="variation">
            Variasjon valgt periode:{' '}
            <TemperatureFormatted temperature={highestThisYear.value - lowestThisYear.value} unit={unit} style={{ marginRight: 0 }} />
          </div>
          {logentryDataset?.avgLastYear && (
            <div className="variationLastyear">
              Variasjon forrige år: <TemperatureFormatted temperature={highestLastYear.value - lowestLastYear.value} unit={unit} />
            </div>
          )}
        </div>
      </CenteredText>
    )
  }
  return <></>
}
