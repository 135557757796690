import React from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'react-loading-skeleton/dist/skeleton.css'
import Skien from './pages/Skien'
import Oslo from './pages/Oslo'
import Furutoppen from './pages/Furutoppen'
import Drivhus from './pages/Drivhus'

const DEBUG = false
const queryClient = new QueryClient()

export const nodeApiGatewayURL = DEBUG ? 'http://localhost:3010/api/' : 'https://linux.midteide.com/api/'

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/skien-loft" element={<Skien />} />
          <Route path="/drivhus" element={<Drivhus />} />
          <Route path="/oslo-outside" element={<Oslo />} />
          <Route path="/cabin-outside" element={<Furutoppen />} />
          <Route path="/" element={<Furutoppen />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
