import styled from '@emotion/styled'
import { Button, Radio } from 'antd'
import { Header } from 'antd/es/layout/layout'

export const AppWrapper = styled.div`
  margin: 0rem;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  min-height: 100%;
  /* background-color: darksand; */

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
  }
`

export const AppWrapperSingle = styled.div`
  margin: 0rem;
  width: 100%;
  min-height: 100%;
  /* background: radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 0.5844931722689075) 100%); */
  background-size: cover; */
  color: white;
  h1,
  h2,
  h3 {
    color: white;
  }
  // Tablet:
  @media screen and (min-width: 768px) {
  }

  // Desktop:
  @media screen and (min-width: 1024px) {
    min-height: 100vh;
  }
`

export const AppContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding: 8px;
  border-radius: 16px;
  margin-top: 32px;
  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    padding: 2rem;
  }
`
export const CenteredContent = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
export const CenteredText = styled.div`
  text-align: center;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  width: fit-content;
  margin: 0 auto;
  padding: 16px;
  /* width: 80%; */
  // Tablet:
  @media only screen and (min-width: 1280px) {
    font-size: 24px;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
`
export const ChatGPTQuoteText = styled.div`
  text-align: center;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  width: fit-content;
  margin: 8px auto;
  padding: 8px;
  font-style: italic;
  /* width: 80%; */
  // Tablet:
  @media only screen and (min-width: 1280px) {
    padding: 16px;
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`
export const CenteredHeadline = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 16px;

  // Tablet:
  @media only screen and (min-width: 1280px) {
    font-size: 48px;
  }
`
export const CenteredTopText = styled.div`
  text-align: center;
  text-decoration: underline;
  display: none;

  // Tablet:
  @media only screen and (min-width: 1280px) {
    display: block;
  }
`

export const ContentScreenMiddleFixed = styled.div`
  position: fixed;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
`
export const LoadingModal = styled.div`
  position: fixed;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -150px; /* Negative half of width. */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  z-index: 999;
`

export const TableContainer = styled.div`
  margin-bottom: 10rem; /* Negative half of height. */
`
export const NavbarContainer = styled(Header)`
  @media only screen and (min-width: 1024px) {
    border-radius: 16px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
`

export const GraphContainer = styled.div`
  display: block;

  // Desktop:
  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
`
