import { useState } from 'react'
import { CenteredContent } from '../styles'
import { DrawGraph } from '../components/Graph/DrawGraph'
import Navbar from '../components/Navbar/Navbar'
import { GraphHeadline } from '../components/GraphHeadLine/GraphHeadline'
import { GraphWrapper } from '../components/Graph/styles'
import { GraphStats } from '../components/GraphStats/GraphStats'
import { useLogEntryDataset } from '../utils/fetchHooks'
import { SHOW_LAST_DAY, SHOW_LAST_WEEK } from '../components/GraphButtons/GraphButtons'

const Drivhus = ({}) => {
  const [showDataFromTimePeriod, setShowDataFromTimePeriod] = useState(SHOW_LAST_DAY)
  const { data: logEntriesDrivhusData, isLoading } = useLogEntryDataset(showDataFromTimePeriod, 'cabin:drivhus', 'temperature', {
    staleTime: 180000,
    refetchInterval: 60000
  })
  const { data: logEntriesKompostData, isLoadingKompost } = useLogEntryDataset(showDataFromTimePeriod, 'cabin:kompost', 'temperature', {
    staleTime: 180000,
    refetchInterval: 60000
  })
  if (isLoading || isLoadingKompost) return <h1> Loading</h1>
  return (
    <div>
      <Navbar />
      <CenteredContent>
        <GraphWrapper>
          <GraphHeadline logEntries={logEntriesDrivhusData} isLoading={isLoading} />
          <GraphStats isLoading={isLoading} logentryDataset={logEntriesDrivhusData} />
          <DrawGraph
            logentryDataset={logEntriesDrivhusData}
            isLoading={isLoading}
            showDataFromTimePeriod={showDataFromTimePeriod}
            setShowDataFromTimePeriod={setShowDataFromTimePeriod}
          />
        </GraphWrapper>
        <GraphWrapper>
          <GraphHeadline logEntries={logEntriesKompostData} isLoading={isLoadingKompost} />
          <GraphStats isLoading={isLoadingKompost} logentryDataset={logEntriesKompostData} />
          <DrawGraph
            logentryDataset={logEntriesKompostData}
            isLoading={isLoadingKompost}
            showDataFromTimePeriod={showDataFromTimePeriod}
            setShowDataFromTimePeriod={setShowDataFromTimePeriod}
          />
        </GraphWrapper>
      </CenteredContent>
    </div>
  )
}

export default Drivhus
