import styled from '@emotion/styled'
import { Button, Radio } from 'antd'

export const GraphWrapper = styled.div`
  width: 100%;
  font-size: 1.5em;
  text-align: center;
  padding: 8px;
  padding-bottom: 1rem;
  /* margin-bottom: 3rem; */
  height: min(100%, 100vh);
  min-height: 100vh;
  hr {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  p {
    color: white;
    /* font-size: 1rem; */
    /* margin-bottom: 4px; */
    /* margin-left: 2rem; */
  }

  h3 {
    margin-bottom: 0;
  }
  h4 {
    font-size: 1rem;
  }

  button {
    margin-top: 1rem;
  }

  /* // Tablet:
  @media only screen and (min-width: 400px) {
    padding: 1rem;
  } */

  .stats {
    margin-bottom: 0;
  }

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    padding: 4rem;
    padding-top: 0;
    /* padding: 5rem; */
    /* height: 100px; */
  }
`

export const Knapp = styled(Button)``

export const BoldOutlined = styled.b`
  text-shadow: white 0 0 3px;
  text-shadow: white 3px;
  text-shadow: #fc0 1px 0 10px;
  text-shadow: 1px 1px 2px pink;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
`
