import React from 'react'
import { Layout, Menu, theme, Typography } from 'antd'
import { Link } from 'react-router-dom'
import './Navbar.scss'
import { NavbarContainer } from '../../styles'
import { HomeOutlined } from '@ant-design/icons'
const { Text } = Typography
export const isMobile = window.innerWidth < 500

const Navbar = ({ hidden = false }) => {
  if (hidden) return

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  const menuItems = () => [
    {
      key: 0,
      label: <Link to={'/cabin-outside'}>{'Furutoppen'}</Link>
    },
    {
      key: 1,
      label: <Link to={'/oslo-outside'}>{'Oslo'}</Link>
    },
    {
      key: 2,
      label: <Link to={'/skien-loft'}>{'Skien'}</Link>
    },
    {
      key: 3,
      label: <Link to={'/drivhus'}>{'Drivhus'}</Link>
    }
  ]
  return (
    <Menu theme="dark" mode="horizontal">
      <Menu.Item key="drangedal" icon={<HomeOutlined />}>
        <Link to="/cabin-outside">Drangedal</Link>
      </Menu.Item>
      <Menu.Item key="oslo" icon={<HomeOutlined />}>
        <Link to="/oslo-outside">Oslo</Link>
      </Menu.Item>
      <Menu.Item key="skien" icon={<HomeOutlined />}>
        <Link to="/skien-loft">Skien</Link>
      </Menu.Item>
      <Menu.Item key="drivhus" icon={<HomeOutlined />}>
        <Link to="/drivhus">Drivhus</Link>
      </Menu.Item>
    </Menu>
  )
  return (
    <Layout style={{ background: 'transparent' }}>
      <NavbarContainer>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={menuItems()} className="navbar" />
      </NavbarContainer>
      {/* </div> */}
    </Layout>
  )
}

export default Navbar
