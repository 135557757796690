import axios from 'axios'
import { useQuery } from 'react-query'
import { nodeApiGatewayURL } from '../App'

export const useDaily = (options = {}) => {
  let URLFull = nodeApiGatewayURL + 'daily/'
  return useQuery(
    ['useDaily'],

    () =>
      axios
        .get(URLFull, {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .then((data) => {
          console.log('Fetching')
          // let dataSorted = data.sort((a, b) => (a.id > b.id ? 1 : -1))
          // tempArray.push(dataSorted)
          return data
          // if (i === graphs.length - 1) setFetchComplete(true)
        })
        .catch((error) => {
          console.error('LogEntries Error:', error)
        }),
    {
      enabled: true,
      ...options
    }
  )
}

export const useLogEntryDataset = (showDataFromTimePeriod, title, type, options = {}) => {
  let URLFull = nodeApiGatewayURL + 'logentries/entries_from_period'
  console.log('useLog useLogEntryDataset: ', showDataFromTimePeriod)
  return useQuery(
    ['useLogEntryDataset', showDataFromTimePeriod, title, type],

    () =>
      axios
        .get(URLFull, {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            type,
            title,
            period: JSON.stringify(showDataFromTimePeriod),
            offset: 0
          },
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .then((data) => {
          console.log('Fetching')
          return data
        })
        .catch((error) => {
          console.error('useLogEntryDataset Error:', error)
        }),
    {
      enabled: true,
      ...options
    }
  )
}
